import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  props: {
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiButton: {
      disableElevation: true,
    },
  },
  palette: {
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#2a3157',
    },
    primary: {
      main: '#0c45e6',
      dark: '#1621db',
    },
    secondary: {
      main: '#19857b',
    },
  },
  typography: {
    body2: {
      fontSize: '1rem',
      lineHeight: '1.75rem',
    },
    allVariants: {
      fontFamily: ['"Inter"', 'sans-serif'].join(','),
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: 32,
          boxShadow: '0 5px 20px rgba(0, 0, 0, 0.08)',
          borderRadius: 5,
          '&:first-of-type': {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          },
          '&::before': {
            content: 'none',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '8px 32px 24px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          display: 'block',
          padding: 0,
        },
        content: {
          margin: 0,
        },
        expandIconWrapper: {
          justifyContent: 'center',
          alignItems: 'center',
          color: '#0045e6',
          backgroundColor: '#efecff',
          borderRadius: 32,
          width: 32,
          height: 32,
          position: 'absolute',
          bottom: 15,
          right: 28,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '13px 24px',
          borderRadius: '5px',
          boxShadow: 'none',
          letterSpacing: 0,
          fontFamily: ['"Inter"', 'sans-serif'].join(','),
          fontSize: '1.125rem',
          fontWeight: 700,
          textTransform: 'none',
          lineHeight: '1.5',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          padding: '15px 20px',
          backgroundColor: '#fff',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: '#fff',
          borderRadius: '5px',
          boxShadow: '0 5px 20px rgba(0, 0, 0, 0.08)',
          '&::-webkit-input-placeholder': {
            opacity: 1,
          },
          '&::-moz-placeholder': {
            opacity: 1,
          },
          '&:-ms-input-placeholder': {
            opacity: 1,
          },
          '&::-ms-input-placeholder': {
            opacity: 1,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: 0,
        },
        h1: {
          fontWeight: 700,
        },
        h2: {
          fontWeight: 700,
        },
        h3: {
          fontWeight: 700,
        },
        h4: {
          fontWeight: 700,
        },
        h5: {
          fontWeight: 700,
        },
        h6: {
          fontWeight: 700,
        },
        gutterBottom: {
          marginBottom: '1.5rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 5px 20px rgba(0, 0, 0, 0.08)',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '80px',
          '@media (min-width: 600px)': {
            minHeight: '80px',
          },
        },
      },
    },
  },
});

export default theme;
