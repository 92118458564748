/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { css } from '@emotion/react';

const Layout = ({ children }) => (
  <div className="page-container">
    <div className="content-wrap">
      <Navbar />
      {children}
      <Container
        component="main"
        maxWidth="xl"
        sx={{
          paddingY: { xs: '3rem', md: '4rem' },
        }}
        css={css`
          padding-top: 3rem;

          @media (min-width: 900px) {
            padding-top: 8rem;
          }
        `}
      >
        <Grid
          container
          rowSpacing={{ xs: 5, sm: 0 }}
          columnSpacing={{ sm: 8, md: 24 }}
        >
          <Grid item xs={12} sm="auto">
            <a href="/" aria-label="Probe homepage">
              <svg width="162" height="30" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="nonzero">
                  <path
                    d="m24.713 5.183.214.207c3.73 3.736 3.798 9.743.207 13.565l-.207.214-9.833 9.833a1 1 0 1 1-1.501-1.319l.093-.085 5.675-5.675c-.438.063-.88.096-1.324.1a9.738 9.738 0 0 1-9.519-7.651l-.055-.269a1 1 0 1 1 1.958-.37 7.751 7.751 0 1 0 2.134-6.931 1 1 0 0 1-1.409-1.412c3.668-3.658 9.52-3.793 13.349-.406l.218.199ZM13.438 22.89l.094.082c.353.359.38.923.078 1.314l-.083.094-1.53 1.53a1 1 0 1 1-1.493-1.328l.093-.085 1.526-1.525a1 1 0 0 1 1.214-.151l.101.069Zm-3.093-3.093.094.082c.358.357.386.92.084 1.312l-.083.094-4.2 4.2a1 1 0 1 1-1.501-1.32l.091-.086 4.2-4.2a1 1 0 0 1 1.214-.151l.101.069ZM7.891 16.24a1 1 0 0 1 .134 1.248l-.076.103-2.363 2.363a1 1 0 1 1-1.499-1.323l.091-.086 2.362-2.363a1 1 0 0 1 1.351.058Zm1.954-8.318.094.082c.358.36.385.925.083 1.316l-.083.094-8.169 8.169a1 1 0 1 1-1.5-1.322l.092-.086L8.53 8.004a1 1 0 0 1 1.214-.151l.101.069Zm10.089-1.875a5.815 5.815 0 0 1 3.462 2.798 1.03 1.03 0 1 1-1.81.985 3.76 3.76 0 0 0-4.91-1.593l-.183.093a1.031 1.031 0 0 1-1.506-.713c-.084-.44.126-.886.52-1.1a5.815 5.815 0 0 1 4.427-.47Zm92.567 13.041a2.627 2.627 0 0 1 2.631-2.602 2.617 2.617 0 0 1 0 5.233 2.633 2.633 0 0 1-2.63-2.631Z"
                    fill="#1DBADF"
                  />
                  <path
                    d="M35.37 6.353h3.327v1.119a6.352 6.352 0 0 1 3.763-1.364l.293-.001a7.611 7.611 0 0 1 7.776 7.503l-.002.297a7.61 7.61 0 0 1-7.477 7.8h-.297a6.335 6.335 0 0 1-4.053-1.331v6.654h-3.33V6.353Zm3.327 4.991v5.142a4.762 4.762 0 0 0 4.023 2.057 4.375 4.375 0 0 0 4.385-4.628 4.374 4.374 0 0 0-4.385-4.628 4.762 4.762 0 0 0-4.023 2.057Zm22.988 2.571a7.834 7.834 0 1 1 15.668 0 7.834 7.834 0 0 1-15.668 0Zm12.25 0a4.421 4.421 0 1 0-8.832 0 4.421 4.421 0 1 0 8.832 0ZM79.67 0h3.327v7.472a6.353 6.353 0 0 1 3.76-1.36l.293-.001a7.61 7.61 0 0 1 7.775 7.503l-.002.297a7.61 7.61 0 0 1-7.476 7.8h-.297a6.336 6.336 0 0 1-4.053-1.331v1.089H79.67V0Zm3.327 11.342v5.142a4.763 4.763 0 0 0 4.023 2.057 4.375 4.375 0 0 0 4.386-4.628 4.375 4.375 0 0 0-4.386-4.628 4.762 4.762 0 0 0-4.023 2.057Zm13.2 2.576a7.608 7.608 0 0 1 2.208-5.552 7.621 7.621 0 0 1 5.532-2.251c4.2 0 7.017 2.964 7.228 7.8v.907H99.641a4.225 4.225 0 0 0 4.147 3.604l.208-.004a5.233 5.233 0 0 0 3.932-1.724l2.238 2.389a8.113 8.113 0 0 1-6.17 2.631 7.635 7.635 0 0 1-7.8-7.8Zm3.54-1.664h7.773a3.562 3.562 0 0 0-3.81-3.085 4.132 4.132 0 0 0-3.964 3.085Zm20.763-5.901h3.327v1.422a5.722 5.722 0 0 1 3.957-1.667l.278.003c3.75 0 6.14 2.6 6.14 6.5v8.862h-3.326v-8.8a3.352 3.352 0 0 0-3.239-3.388h-.21a3.6 3.6 0 0 0-3.6 2.662v9.527H120.5V6.353Zm23.511-.238c4.2 0 7.017 2.964 7.228 7.8v.907H139.72a4.225 4.225 0 0 0 4.147 3.604l.208-.004a5.233 5.233 0 0 0 3.932-1.724l2.238 2.389a8.114 8.114 0 0 1-6.17 2.631 7.635 7.635 0 0 1-7.8-7.8l-.007-.003a7.621 7.621 0 0 1 7.743-7.8Zm13.315-4.295v4.533h4.6l-.726 2.964h-3.87v6.866c0 1.543.422 2.239 2.147 2.239h2.086v3.05h-2.933a4.238 4.238 0 0 1-4.628-4.6V9.317h-2.21l-.727-2.964h2.934v-2.9l3.327-1.633Zm-13.55 7.349a4.132 4.132 0 0 0-3.962 3.085h7.773a3.563 3.563 0 0 0-3.81-3.085Zm-87.67 12.12h-3.3v-8.991a6.4 6.4 0 0 1 6.4-6.4h1.9v3.3h-1.9a3.1 3.1 0 0 0-3.1 3.1v8.991Z"
                    fill="#00267B"
                  />
                </g>
              </svg>
            </a>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography
              variant="h5"
              sx={{ marginBottom: '0.75rem', fontSize: '1rem' }}
            >
              Probe.net
            </Typography>
            <ul className="footer-menu">
              <li>
                <Link color="#2A3157" underline="none" href="mailto:info@probe.net">
                  Contact us
                </Link>
              </li>
              <li>
                <Link color="#2A3157" underline="none" href="https://app.probe.net">
                  Login Probe
                </Link>
              </li>
              {/* <li>
                <Link color="#2A3157" underline="none" href="#">
                  About Probe.net
                </Link>
              </li> */}
            </ul>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography
              variant="h5"
              sx={{ marginBottom: '0.75rem', fontSize: '1rem' }}
            >
              Follow Us
            </Typography>
            <ul>
              <li>
                <Link
                  color="#2A3157"
                  underline="none"
                  href="https://www.linkedin.com/company/probe-net"
                  sx={{ display: 'flex ' }}
                >
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    css={css`
                      margin-right: 0.25rem;
                    `}
                  >
                    <g fill="none" fillRule="evenodd">
                      <circle fill="#C9CBCF" cx="12" cy="12" r="12" />
                      <path
                        d="M18 18h-2.484v-3.897c0-.93-.019-2.126-1.294-2.126-1.295 0-1.493 1.012-1.493 2.057V18h-2.483V9.986h2.386v1.092h.032c.333-.63 1.144-1.295 2.355-1.295 2.516 0 2.981 1.659 2.981 3.818V18ZM7.44 8.889C6.642 8.889 6 8.24 6 7.444a1.442 1.442 0 1 1 2.884 0c0 .797-.647 1.445-1.443 1.445ZM8.687 18h-2.49V9.986h2.49V18Z"
                        fill="#FFF"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                  <span>Linked In</span>
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </div>
    <Footer />
  </div>
);

export default Layout;
