/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import HeroMp4 from '../../probe.mp4';
import HeroWebm from '../../probe.webm';

export default function VideoBackground() {
  return (
    <>
      <div
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 20;
          background-color: rgba(0, 0, 0, 0.5);
        `}
      />
      <video
        crossOrigin="anonymous"
        preload="auto"
        autoPlay
        loop
        playsInline
        muted
        css={css`
          z-index: 10;
          position: absolute;
          inset: 0;
          width: auto;
          min-width: 100%;
          min-height: 100%;
          max-width: none;
        `}
      >
        <source src={HeroWebm} type="video/webm" />
        <source src={HeroMp4} type="video/mp4" />
      </video>
    </>
  );
}
